<template>
  <v-data-table :headers="headers" :items="desserts" class="ma-2 elevation-1">
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>ПОЛЬЗОВАТЕЛИ</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on"
              >Новый
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-form v-model="formValidity" ref="form">
              <v-card-text>
                <v-container>
                  <v-text-field
                    v-model="editedItem.surname"
                    label="Фамилия"
                    :rules="requiredRules"
                    required
                  />
                  <v-text-field
                    v-model="editedItem.name"
                    label="Имя"
                    :rules="requiredRules"
                    required
                  />
                  <v-text-field
                    v-model="editedItem.patronym"
                    label="Отчество"
                  />
                  <v-text-field
                    v-model="editedItem.email"
                    label="email"
                    :rules="emailRules"
                    required
                  />
                  <v-text-field
                    v-model="editedItem.password"
                    label="password"
                    :type="showPassword ? 'text' : 'password'"
                    @click:append="showPassword = !showPassword"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[passwordRules]"
                    required
                  />
                  <v-select
                    :items="rolesOptions"
                    v-model="editedItem.role_id"
                    item-text="name"
                    item-value="id"
                    label="Роль"
                    :rules="requiredRules"
                    required
                  />
                  <v-select
                    v-if="editedItem.role_id === 5"
                    :items="districts"
                    v-model="editedItem.district_id"
                    item-text="name"
                    item-value="id"
                    label="Район"
                    :rules="requiredRules"
                    required
                  />
                </v-container>
              </v-card-text>
            </v-form>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Отмена</v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
                :disabled="!formValidity"
                >Сохранить</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">
        mdi-pencil
      </v-icon>
      <v-icon small @click="deleteItem(item)">
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize">Обновить</v-btn>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "UsersTab",

  data: () => ({
    inputName: "",
    dialog: false,
    headers: [
      {
        text: "Фамилия",
        align: "start",
        value: "surname"
      },
      {
        text: "Имя",
        align: "start",
        value: "name"
      },
      {
        text: "Отчество",
        align: "start",
        value: "patronym"
      },
      {
        text: "Email",
        align: "start",
        value: "email"
      },
      {
        text: "Роль",
        align: "start",
        value: "roles"
      },
      { text: "Действия", value: "actions", sortable: false, width: 100 }
    ],
    editedIndex: -1,
    editedItem: {
      name: "",
      surname: "",
      patronym: "",
      email: "",
      password: "",
      role_id: "",
      district_id: ""
    },
    defaultItem: {
      name: "",
      surname: "",
      patronym: "",
      email: "",
      password: "",
      role_id: "",
      district_id: ""
    },
    tempRoleId: null,
    tempPassword: null,
    showPassword: false,
    requiredRules: [value => !!value || "Обязательное поле"],
    emailRules: [
      value => !!value || "Обязательное поле",
      value =>
        value.includes("@") || "Электронная почта должна содержать символ @"
    ],
    formValidity: false
  }),

  computed: {
    ...mapGetters(["allUsersData"]),
    formTitle() {
      return this.editedIndex === -1 ? "Новый" : "Редактировать";
    },
    desserts() {
      let arr = [];
      if (Object.keys(this.allUsersData).length === 0) return arr;
      this.allUsersData.forEach(e => {
        return arr.push({
          id: e.id,
          name: e.name,
          surname: e.surname,
          patronym: e.patronym,
          email: e.email,
          role_id: e.roles[0] ? e.roles[0].id : "",
          roles: e.roles[0] ? e.roles[0].name : "",
          district_id: e.district_id
        });
      });
      return arr;
    },
    rolesOptions() {
      return this.$store.state.roles.rolesData;
    },
    districts() {
      return this.$store.state.settings.disctricts;
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  methods: {
    ...mapActions([
      "getUsersData",
      "getRolesData",
      "destroyUserData",
      "createUserData",
      "pushUserData",
      "addUserRole",
      "userPassword"
    ]),
    initialize() {
      this.getUsersData();
      this.getRolesData();
    },
    async save() {
      let userId;
      if (this.editedIndex > -1) {
        userId = this.editedItem.id;
        await this.pushUserData({
          id: this.editedItem.id,
          name: this.editedItem.name,
          surname: this.editedItem.surname,
          patronym: this.editedItem.patronym,
          email: this.editedItem.email,
          district_id:
            this.editedItem.role_id === 5 ? this.editedItem.district_id : null
        });
      } else {
        userId = await this.createUserData({
          name: this.editedItem.name,
          surname: this.editedItem.surname,
          patronym: this.editedItem.patronym,
          email: this.editedItem.email,
          district_id:
            this.editedItem.role_id === 5 ? this.editedItem.district_id : null
        });
      }
      if (
        this.editedItem.role_id &&
        this.tempRoleId !== this.editedItem.role_id
      ) {
        await this.addUserRole({
          user_id: userId,
          role_id: this.editedItem.role_id
        });
      }
      if (
        this.editedItem.password &&
        this.tempPassword !== this.editedItem.password
      ) {
        await this.userPassword({
          id: userId,
          password: this.editedItem.password
        });
      }
      this.$store.dispatch("getUsersData");
      this.close();
    },
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
      this.tempRoleId = item.role_id;
      this.tempPassword = item.password;
    },
    deleteItem(item) {
      if (confirm("Вы действительно хотите удалить?")) {
        this.destroyUserData(item.id);
      }
    },
    close() {
      this.$refs.form.resetValidation();
      this.dialog = false;
      this.tempRoleId = "";
      this.tempPassword = "";
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    passwordRules(value) {
      if (this.editedIndex === -1) {
        return !!value || "Обязательное поле";
      }
      return true;
    }
  }
};
</script>

<style scoped></style>
